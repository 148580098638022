import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
} from 'reactstrap'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import uuidv1 from 'uuid/v1'
import Keys from '../../../utils/Keys.json'
import { isNil } from 'lodash'
import { setTaskToLocalStorage } from '../../../utils/taskHandler'
import moment from 'moment'

const CloneTaskModal = ({
  showCloneTaskModal,
  setShowCloneTaskModal,
  setEditTask,
  setShowTasksModal,
  selectedTask,
  subTasks,
  setTasksAndTasksData,
  tasks,
  tasksData,
  setSubTasks,
  setCloneMode,
  cloneMode,
  selectedGroup,
  selected_rid,
  setTasksData,
}) => {
  const close = () => {
    setEditTask(false)
    setShowCloneTaskModal(false)
    setShowTasksModal(false)
    setCloneMode(null)
  }

  const cloneTask = () => {
    setShowTasksModal(false)
    const newOid = uuidv1()
    const newTid = uuidv1()
    const newRefId = uuidv1()
    const { categories } = selectedTask
    const currentCategories = {}
    const newSubtasks = {}
    Object.keys(categories).forEach(category => {
      const newSubtaskOrder = categories[category][Keys.ST_ORDER].map(
        subTaskId => {
          const newSubTaskId = uuidv1()
          const newSubTask = {
            ...subTasks[subTaskId],
            st_id: newSubTaskId,
          }
          if (cloneMode === Keys.CLONE_CLONE_TASK_MODE) {
            newSubTask.status = null
            newSubTask.cover_img = [null]
          }
          newSubtasks[newSubTaskId] = newSubTask
          return newSubTaskId
        }
      )
      setSubTasks({ ...subTasks, ...newSubtasks })
      currentCategories[category] = {
        ...categories[category],
        sub_tasks_order: newSubtaskOrder,
      }
    })

    const clonedTask = {
      ...selectedTask,
      cloned: true,
      tid: newTid,
      ref_id: newRefId,
      categories: currentCategories,
      data_oid: newOid,
      utc_offset: isNil(selectedTask.utc_offset)
        ? Number(moment().utcOffset() / 60)
        : selectedTask.utc_offset,
    }

    if (cloneMode === Keys.CLONE_CLONE_TASK_MODE) {
      clonedTask.is_opened = false
    }
    let currentTaskData = tasksData[selectedTask.data_oid]
    currentTaskData.oid = newOid
    const newTaskData = {
      [newOid]: currentTaskData,
    }

    const indexCurrentTask = tasks.findIndex(
      task => task[Keys.TASK_ID] === selectedTask[Keys.TASK_ID]
    )
    tasks.splice(indexCurrentTask + 1, 0, clonedTask)
    setTasksAndTasksData(tasks, { ...tasksData, newSubtasks })
    setTasksData({ ...tasksData, ...newTaskData })
    setShowCloneTaskModal(false)
    setTaskToLocalStorage(
      selected_rid,
      selectedGroup.name,
      {
        task: clonedTask,
        subTasks: newSubtasks,
      },
      Keys.CLONED_TASK_LS_PREFIX
    )
  }

  return (
    <Modal isOpen={showCloneTaskModal} toggle={close}>
      <ModalHeader>Clone task</ModalHeader>
      <ModalBody>
        <ButtonGroup
          className="d-block"
          onChange={e => setCloneMode(+e.target.value)}
        >
          {/*# TODO: edit mode turned off*/}
          {/*<div className="d-flex align-items-center">*/}
          {/*  <input*/}
          {/*    type="radio"*/}
          {/*    name="cloneMode"*/}
          {/*    value={Keys.EDIT_CLONE_TASK_MODE}*/}
          {/*  />*/}
          {/*  <span className="ml-2">edit mode</span>*/}
          {/*</div>*/}
          <div className="d-flex align-items-center">
            <input
              type="radio"
              name="cloneMode"
              value={Keys.CLONE_CLONE_TASK_MODE}
            />
            <span className="ml-2">clone mode</span>
          </div>
        </ButtonGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={cloneTask} disabled={isNil(cloneMode)}>
          ok
        </Button>
        <Button color="danger" onClick={close}>
          cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = ({
  showCloneTaskModal,
  selectedTask,
  subTasks,
  setTasksAndTasksData,
  tasks,
  tasksData,
  setSubTasks,
  setShowTasksModal,
  cloneMode,
  setCloneMode,
  selectedGroup,
  selected_rid,
  setTasksData,
}) => ({
  showCloneTaskModal,
  selectedTask,
  subTasks,
  setTasksAndTasksData,
  tasks,
  tasksData,
  setSubTasks,
  setShowTasksModal,
  cloneMode,
  setCloneMode,
  selectedGroup,
  selected_rid,
  setTasksData,
})

export default connect(mapStateToProps, actions)(CloneTaskModal)
